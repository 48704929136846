import { Component, Input } from '@angular/core';
import { PlayerStatisticsPerformanceModel } from 'models';

@Component({
    selector: 'app-player-performance',
    templateUrl: './player-performance.component.html',
    styleUrls: ['./player-performance.component.scss'],
})
export class PlayerPerformanceComponent {
    @Input()
    public performance: Array<PlayerStatisticsPerformanceModel> = [];
}
