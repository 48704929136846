import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import { APP_INITIALIZER, ErrorHandler, Injectable, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { CoreModule } from './core/core.module';

import { registerLocaleData } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AppHttpInterceptor } from 'app-http-interceptor';
import { AppMaterialModule } from 'app-material.module';
import { fooswarsAppInitializer } from 'core/app-initializer';
import { AuthInterceptor } from 'core/auth.interceptor';
import { BrowserStorageService } from 'core/browser-storage.service';
import { authorizedGuard } from 'core/guards';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { effects, metaReducers, reducers } from 'store';
import { FormsModule } from '../../node_modules/@angular/forms';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ApplicationErrorHandler } from './error-handler';
import { HallOfShameComponent } from './hall-of-shame/hall-of-shame.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PlayerPerformanceComponent } from './players/player-performance/player-performance.component';
import { PlayersComponent } from './players/players.component';
import { SharedModule } from './shared/shared.module';

@Injectable({ providedIn: 'root' })
class InjectableTranslateMessageFormatCompiler extends TranslateMessageFormatCompiler {}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', `.json?v=${environment.version}`);
}

const routes: Routes = [
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
    },
    {
        path: 'account',
        loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [authorizedGuard],
        data: {
            pageTitle: 'PAGE_TITLE.DASHBOARD',
        },
    },
    {
        path: 'players',
        component: PlayersComponent,
        canActivate: [authorizedGuard],
        data: {
            pageTitle: 'PAGE_TITLE.PLAYERS',
        },
    },
    {
        path: 'statistics',
        loadChildren: () => import('./statistics/statistics.module').then((m) => m.StatisticsModule),
        canActivate: [authorizedGuard],
        data: {
            pageTitle: 'PAGE_TITLE.STATISTICS',
        },
    },
    {
        path: 'hall-of-shame',
        component: HallOfShameComponent,
        canActivate: [authorizedGuard],
        data: {
            pageTitle: 'PAGE_TITLE.HALL_OF_SHAME',
        },
    },
    {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [authorizedGuard],
        data: {
            pageTitle: 'PAGE_TITLE.SETTINGS',
        },
    },
    {
        path: 'achievements',
        loadChildren: () => import('./achievements/achievements.module').then((m) => m.AchievementsModule),
        canActivate: [authorizedGuard],
        data: {
            pageTitle: 'PAGE_TITLE.ACHIEVEMENTS',
        },
    },
    {
        path: 'widgets',
        loadChildren: () => import('./widgets/widgets.module').then((m) => m.WidgetsModule),
        canActivate: [authorizedGuard],
    },
    {
        path: 'notifications',
        component: NotificationsComponent,
        canActivate: [authorizedGuard],
        data: {
            pageTitle: 'PAGE_TITLE.NOTIFICATIONS',
        },
    },
    {
        path: '**',
        redirectTo: '/dashboard',
    },
];

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        PlayersComponent,
        HallOfShameComponent,
        NotificationsComponent,
        PlayerPerformanceComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: InjectableTranslateMessageFormatCompiler,
            },
        }),
        BrowserModule,
        RouterModule.forRoot(routes),
        CoreModule,
        BrowserAnimationsModule,
        SharedModule,
        InfiniteScrollModule,
        ServiceWorkerModule.register('/ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerWhenStable:30000',
        }),
        FormsModule,
        StoreModule.forRoot(reducers, {
            metaReducers: metaReducers,
            runtimeChecks: {
                strictActionImmutability: false,
                strictStateImmutability: false,
            },
        }),
        EffectsModule.forRoot(effects),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            connectInZone: true,
        }),
        AppMaterialModule,
        SweetAlert2Module.forRoot({
            provideSwal: () =>
                import('sweetalert2').then(({ default: swal }) =>
                    swal.mixin({
                        backdrop: false,
                    }),
                ),
        }),
    ],
    providers: [
        {
            provide: LOCALE_ID,
            deps: [TranslateService],
            useFactory: (tran: TranslateService) => tran.currentLang || 'cs',
        },
        {
            provide: ErrorHandler,
            useClass: ApplicationErrorHandler,
            deps: [Injector],
        },
        { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales: ['cs', 'en'] } },
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [BrowserStorageService],
            useFactory: fooswarsAppInitializer,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {
    constructor() {
        registerLocaleData(localeCs, 'cs');
    }
}
