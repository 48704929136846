<div class="l-tables mt-2">
    <app-main-scoreboard class="table" [items]="mainScoreboard$ | async" [height]="boxHeight"></app-main-scoreboard>

    @if (settings(); as settings) {
        @switch (settings.teamsScoreboardMode) {
            @case (teamsScoreboardMode.EloPoints) {
                <app-elo-scoreboard
                    class="table d-none d-md-flex"
                    [height]="boxHeight"
                    [items]="eloScoreboard$ | async"
                ></app-elo-scoreboard>
            }
            @default {
                <app-teams-scoreboard
                    class="table d-none d-md-flex"
                    [height]="boxHeight"
                    [items]="teamScoreboard$ | async"
                ></app-teams-scoreboard>
            }
        }
    }
</div>
<div class="l-newMatch d-none d-xxl-flex">
    <span class="fsFAB_add" (click)="addMatchModal()">
        <i class="material-icons">add</i>
        <span class="">{{ 'DASHBOARD.ADD_MATCH' | translate }}</span>
    </span>
</div>

<div class="mt-4 text-center d-none d-xxl-flex flex-column align-items-center" *ngIf="matches?.length === 0">
    <mat-icon>arrow_upward</mat-icon>
    {{ 'DASHBOARD.NEW_MATCH_CTA' | translate }}
</div>

<div class="mt-4 text-center" *ngIf="matches?.length">
    <mat-checkbox color="primary" (change)="onlyMineChanged()" [(ngModel)]="showOnlyMineMatches">{{
        'DASHBOARD.SHOW_ONLY_MINE' | translate
    }}</mat-checkbox>
</div>

<app-ongoing-match-box [match]="m" [first]="first" *ngFor="let m of ongoingMatches$ | async; let first = first"></app-ongoing-match-box>

<div infinite-scroll (scrolled)="loadNext()" [infiniteScrollThrottle]="300">
    <app-match-box #matchBox [attr.data-match-id]="m.id" [match]="m" *ngFor="let m of matches"></app-match-box>
</div>
