import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { BrowserStorageService, storageKeys } from 'core/browser-storage.service';
import { PushNotificationService } from 'core/push-notifications.service';
import { ThemeService } from 'core/theme.service';
import { EMPTY, getRandomInt } from 'core/utils';
import { Settings as LuxonSettings } from 'luxon';
import { Season } from 'models';
import { Observable, interval, of } from 'rxjs';
import { exhaustMap, filter, take } from 'rxjs/operators';
import { AboutComponent } from 'shared/about/about.component';
import { InviteModalComponent } from 'shared/invite-modal/invite-modal.component';
import { selectLastApiError, selectLastBadRequestApiError, selectLastForbiddenApiError } from 'store/error/error.reducer';
import { selectActiveMatch } from 'store/matches/matches.reducers';
import { loadSeasons } from 'store/season/season.actions';
import { toggleSidebar } from 'store/sidebar/sidebar.actions';
import { environment } from '../environments/environment';
import { Session } from './core/session.service';
import { SignalRService } from './core/signalr.service';
import { AddNewMatchComponent } from './shared/add-new-match/add-new-match.component';
import { AppState, matchesActions, sidebarActions } from './store';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    public readonly version = environment.version;
    public seasons$: Observable<Array<Season>>;
    public showSeasonSelect$: Observable<boolean>;
    public activeSeason$: Observable<Season>;

    public appInstalled = false;
    public showReleaseNotesStrip = false;
    public currentModule: 'default' | 'widgets' | 'account' = 'default';

    public bottomMenuVisible = true;

    public sideBarOpened = false;
    public canSidebarBeOpened = true;

    public randomImageClass = `image-${getRandomInt(1, 7)}`;

    @ViewChild('apiErrorSwal')
    public readonly apiErrorSwal: SwalComponent;

    @ViewChild('apiForbiddenSwal')
    public readonly apiForbiddenSwal: SwalComponent;

    @ViewChild('apiBadRequestSwal')
    public readonly apiBadRequestSwal: SwalComponent;

    public apiBadRequestSwalText = '';

    public swalTraceId = 'No trace id provided';

    @HostListener('window:focus')
    public onWindowFocus() {
        this.signalR.checkConnection();
    }

    @HostListener('window:resize')
    public onWindowResize() {
        this.setSidebarState();
        this.canSidebarBeOpened = window.innerWidth > 1400;
    }
    constructor(
        public readonly session: Session,
        private readonly swUpdate: SwUpdate,
        private readonly store: Store<AppState>,
        private readonly signalR: SignalRService,
        private readonly dialog: MatDialog,
        readonly translate: TranslateService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly storage: BrowserStorageService,
        themeService: ThemeService,
        private readonly pushService: PushNotificationService,
    ) {
        themeService.setInitialTheme();
        if (environment.version !== this.storage.local.getItem(storageKeys.version)) {
            this.showReleaseNotesStrip = true;
            this.storage.local.setItem(storageKeys.version, environment.version);
        }
        translate.onLangChange.subscribe(() => {
            const current = translate.currentLang;
            if (current.startsWith('cs')) {
                LuxonSettings.defaultLocale = 'cs';
            } else {
                LuxonSettings.defaultLocale = 'en';
            }
        });

        store.pipe(select((x) => x.sidebar.opened)).subscribe((opened) => {
            this.sideBarOpened = opened;
            this.setSidebarState();
        });

        translate.addLangs(['en', 'cs']);

        if (this.storage.local.getItem(storageKeys.showReleaseNotesStrip)) {
            this.showReleaseNotesStrip = true;
            this.storage.local.removeItem(storageKeys.showReleaseNotesStrip);
        }

        let lang = this.storage.local.getItem(storageKeys.lang);
        lang = this.translate.langs.find((x) => x === lang) || 'en';
        if (lang) {
            translate.use(lang);
        } else {
            const browserLang = translate.getBrowserCultureLang();
            if (browserLang.startsWith('cs')) {
                translate.use('cs');
            } else {
                translate.use('en');
            }
            this.storage.local.setItem(storageKeys.lang, translate.currentLang);
        }
    }

    ngOnInit() {
        this.canSidebarBeOpened = window.innerWidth > 1400;
        this.pushService.subscribeToNotificationActions();
        this.seasons$ = this.store.pipe(select((x) => x.season.all));
        this.showSeasonSelect$ = this.store.pipe(select((x) => x.season.showSelect));
        setTimeout(() => {
            this.store.dispatch(matchesActions.restoreActiveMatch());
        });

        this.activeSeason$ = this.store.pipe(select((x) => x.season.active));

        this.session.userInitialized.pipe(take(1)).subscribe(() => {
            this.store.dispatch(loadSeasons());
        });
        this.handleAppUpdates();

        this.router.events.pipe(filter((x) => x instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
            if (e.url.startsWith('/widgets') || this.route.snapshot.queryParams['app'] === 'maui') {
                this.currentModule = 'widgets';
                return;
            }
            if (e.url.startsWith('/account')) {
                this.currentModule = 'account';
                return;
            }
            this.currentModule = 'default';
        });

        this.route.queryParams.subscribe((q) => {
            if (q.showSideBar) {
                this.store.dispatch(toggleSidebar());
            }
        });

        this.store
            .pipe(
                select(selectLastApiError),
                filter((x) => !!x),
                exhaustMap((err) => of(err)),
            )
            .subscribe((err) => {
                this.swalTraceId = `<span class="text-center text-muted text-small">Trace id: ${err.traceId}</span>`;
                setTimeout(() => {
                    this.apiErrorSwal.fire().then(EMPTY, EMPTY);
                });
            });
        this.store
            .pipe(
                select(selectLastForbiddenApiError),
                filter((x) => !!x),
                exhaustMap((err) => of(err)),
            )
            .subscribe(() => {
                setTimeout(() => {
                    this.apiForbiddenSwal.fire().then(EMPTY, EMPTY);
                });
            });
        this.store
            .pipe(
                select(selectLastBadRequestApiError),
                filter((x) => !!x),
                exhaustMap((err) => of(err)),
            )
            .subscribe((err) => {
                if (this.currentModule === 'account') {
                    return;
                }
                if (err.error) {
                    this.apiBadRequestSwalText = Object.values(err.error)
                        .map((value) => value.map((x) => String(this.translate.instant(x)) || x))
                        .reduce((a, b) => a.concat(b), [])
                        .join('<br />');
                }
                setTimeout(() => {
                    this.apiBadRequestSwal.fire().then(EMPTY, EMPTY);
                });
            });

        this.store
            .pipe(
                select(selectActiveMatch),
                filter((x) => x?.restored === true),
                take(1),
            )
            .subscribe((active) => {
                if (active) {
                    this.dialog.open(AddNewMatchComponent, {
                        position: { top: '20px' },
                        disableClose: true,
                        width: '95%',
                        maxWidth: '800px',
                    });
                }
            });
    }

    public showReleaseNotes() {
        this.dialog
            .open<AboutComponent>(AboutComponent, { maxWidth: '850px' })
            .afterClosed()
            .subscribe(() => {
                this.showReleaseNotesStrip = false;
            });
    }

    public openInviteModal() {
        this.dialog.open(InviteModalComponent, { autoFocus: false });
    }

    public onSidebarClosed() {
        if (this.canSidebarBeOpened) {
            this.store.dispatch(sidebarActions.closeSidebar());
        }
    }

    private handleAppUpdates() {
        if (!this.swUpdate.isEnabled) {
            return;
        }

        interval(10 * 60 * 1000).subscribe(() => {
            this.swUpdate.checkForUpdate().catch(EMPTY);
        });
        this.swUpdate.versionUpdates.subscribe((event) => {
            if (event.type === 'VERSION_READY') {
                this.swUpdate
                    .activateUpdate()
                    .then(() => document.location.reload())
                    .catch(EMPTY);
            }
        });
    }

    private setSidebarState() {
        const isOnNotificationRoute = this.router.url.startsWith('/notifications');
        if (!isOnNotificationRoute && this.sideBarOpened && !this.canSidebarBeOpened) {
            this.router.navigate(['/notifications']).catch(EMPTY);
        } else if (isOnNotificationRoute && (this.canSidebarBeOpened || !this.sideBarOpened)) {
            this.router.navigate(['/dashboard']).catch(EMPTY);
        }
    }
}
