import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { map } from 'rxjs/operators';
import { GetMatchModelExt } from './entities';

@Injectable({
    providedIn: 'root',
})
export class MatchesService {
    constructor(private readonly http: HttpClient) {}

    load(take: number, skip: number, season: number, filter: 'all' | 'mine' | 'shame') {
        let params = new HttpParams()
            .set('take', (take || 1).toString())
            .set('skip', skip.toString())
            .set('filter', filter);
        if (season != null) {
            params = params.set('season', season.toString());
        }
        return this.http.get<{ values: Array<GetMatchModelExt> }>('/api/matches', { params: params }).pipe(
            map((x) => {
                const res = x.values;
                let date: DateTime = null;
                if (res[0]) {
                    date = DateTime.fromISO(res[0].createdAt).startOf('day');
                }
                res.forEach((m, ix) => {
                    const d = DateTime.fromISO(m.createdAt).startOf('day');
                    if (!d.equals(date)) {
                        m.showDate = true;
                        date = d;
                    } else {
                        m.showDate = ix === 0; // show first date
                    }
                });
                return res;
            }),
        );
    }
}
