import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ApplicationTheme, CurrentUserModel } from 'models';
import { AppState } from 'store';
import { BrowserStorageService, storageKeys } from './browser-storage.service';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    private _user: CurrentUserModel;
    private _currentTheme: ApplicationTheme;
    constructor(
        private readonly store: Store<AppState>,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly storage: BrowserStorageService,
    ) {
        this.store.pipe(select((x) => x.user.current)).subscribe((user) => {
            this._user = user;
            if (user && this._currentTheme !== user.theme) {
                this._currentTheme = user.theme;
                this.storage.local.setItem(storageKeys.theme, user.theme);
                this.setTheme(user.theme);
            }
        });

        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
            if (this._user?.theme === ApplicationTheme.UseSystemTheme) {
                if (e.matches) {
                    this.setTheme(ApplicationTheme.Light);
                } else {
                    this.setTheme(ApplicationTheme.Dark);
                }
            }
        });
    }

    public setInitialTheme() {
        let theme = this._user?.theme || (this.storage.local.getItem(storageKeys.theme) as ApplicationTheme) || this.getSystemTheme();
        this._currentTheme = theme;
        if (theme === ApplicationTheme.UseSystemTheme) {
            theme = this.getSystemTheme();
        }
        const htmlElement = this.document.querySelector('html');
        htmlElement.classList.add(`theme-${theme}`);
        htmlElement.setAttribute('data-bs-theme', theme);
    }

    private setTheme(theme: ApplicationTheme) {
        const htmlElement = this.document.querySelector('html');
        if (theme === ApplicationTheme.UseSystemTheme) {
            theme = this.getSystemTheme();
        }
        [ApplicationTheme.Light, ApplicationTheme.Dark].forEach((x) => htmlElement.classList.remove(`theme-${x}`));
        htmlElement.classList.add(`theme-${theme}`);
        htmlElement.setAttribute('data-bs-theme', theme);
    }

    private getSystemTheme() {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            return ApplicationTheme.Dark;
        }
        return ApplicationTheme.Light;
    }
}
