<ng-template #routerOutlet>
    <router-outlet> </router-outlet>
</ng-template>

<main id="app-root">
    <!-- <ngx-loading-bar [includeSpinner]="false"></ngx-loading-bar> -->
    <div *ngIf="currentModule === 'account'" class="container-fluid account-layout" [ngClass]="randomImageClass">
        <div class="d-flex justify-content-center align-items-center account-wrapper">
            <ng-container *ngTemplateOutlet="routerOutlet"></ng-container>
        </div>
    </div>
    <div *ngIf="currentModule === 'widgets'" class="container-fluid">
        <ng-container *ngTemplateOutlet="routerOutlet"></ng-container>
    </div>

    <div *ngIf="currentModule === 'default'">
        <app-topbar *ngIf="session.user.isAuthenticated"></app-topbar>
        <mat-sidenav-container class="container-fluid l-content" [class.bottom-menu-visible]="bottomMenuVisible" [hasBackdrop]="true">
            <mat-sidenav-content [class.limit-content-height]="sideBarOpened && canSidebarBeOpened">
                <div class="alert alert-success alert-dismissible fade show text-center mb-0" role="alert" *ngIf="showReleaseNotesStrip">
                    {{ 'RELEASE_NOTES.UPDATED' | translate: { version: version } }}
                    <a (click)="showReleaseNotes()" href="javascript:void(0)">{{
                        'RELEASE_NOTES.UPDATED_SHOW_RELEASE_BOTES' | translate
                    }}</a>
                    <button type="button" class="btn-close" (click)="showReleaseNotesStrip = false"></button>
                </div>
                <div class="alert alert-warning text-center" *ngIf="session.user.teamMembersCount < 4" role="alert">
                    {{
                        'SETTINGS.INVITE_MORE_PLAYERS'
                            | translate
                                : {
                                      remaining: 4 - session.user.teamMembersCount,
                                  }
                    }}
                    <a href="javascript:void(0)" (click)="openInviteModal()">{{ 'SETTINGS.INVITE_MORE_PLAYERS_LINK' | translate }}</a>
                </div>
                <app-allow-push-notifications></app-allow-push-notifications>
                <app-update-in-progress-alert></app-update-in-progress-alert>
                <div
                    class="d-flex justify-content-center pt-3"
                    *ngIf="session.user.isAuthenticated && (showSeasonSelect$ | async) && (seasons$ | async).length > 1"
                >
                    <app-season-select [seasons]="seasons$ | async" [activeSeason]="activeSeason$ | async"></app-season-select>
                </div>
                <div class="container content" [class.pt-3]="(seasons$ | async).length === 1">
                    <ng-container *ngTemplateOutlet="routerOutlet"></ng-container>
                </div>
            </mat-sidenav-content>
            <mat-sidenav
                [autoFocus]="false"
                #sidenav
                mode="over"
                position="end"
                [opened]="sideBarOpened && canSidebarBeOpened"
                (closed)="onSidebarClosed()"
            >
                <app-sidebar *ngIf="sideBarOpened && canSidebarBeOpened"></app-sidebar>
            </mat-sidenav>
        </mat-sidenav-container>
        <app-bottom-menu-bar *ngIf="session.user.isAuthenticated" (visibleChanged)="bottomMenuVisible = $event"></app-bottom-menu-bar>
        <footer class="footer d-none d-xxl-block">
            <div class="container">
                <span (click)="showReleaseNotes()" class="float-right text-muted clickable">v{{ version }}</span>
            </div>
        </footer>
    </div>
</main>

<swal
    #apiErrorSwal
    [title]="'COMMON.API_ERROR_MESSAGE_TITLE' | translate"
    [text]="'COMMON.API_ERROR_MESSAGE' | translate"
    icon="error"
    [footer]="swalTraceId"
>
</swal>
<swal
    #apiForbiddenSwal
    [title]="'COMMON.API_FORBIDDEN_MESSAGE_TITLE' | translate"
    [text]="'COMMON.API_FORBIDDEN_MESSAGE' | translate"
    icon="warning"
>
</swal>
<swal #apiBadRequestSwal [title]="'COMMON.API_BAD_REQUEST_MESSAGE_TITLE' | translate" [text]="apiBadRequestSwalText" icon="warning"> </swal>
