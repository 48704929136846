import { Observable, of } from 'rxjs';
import { BrowserStorageService, storageKeys } from './browser-storage.service';
import { createUniqueId } from './utils';

export function fooswarsAppInitializer(storage: BrowserStorageService): () => Observable<any> {
    return () => {
        let browserId = storage.local.getItem(storageKeys.browserId);
        if (!browserId) {
            browserId = createUniqueId();
            storage.local.setItem(storageKeys.browserId, browserId);
        }
        return of(null);
    };
}
