import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { TokenService } from './token.service';
import { EMPTY } from './utils';

interface ITokens {
    accessToken: string;
    refreshToken: string;
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private _refreshTokenInProgress = false;
    private _tokenRefreshedSource = new Subject<ITokens>();
    private _tokenRefreshed$ = this._tokenRefreshedSource.asObservable();

    constructor(
        private readonly router: Router,
        private readonly tokenService: TokenService,
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.url.startsWith('/assets') || request.url.startsWith(`/api/${environment.apiVersion}/token`)) {
            return next.handle(request);
        }
        const refreshToken = this.tokenService.getRefreshToken();
        if (!refreshToken) {
            return next.handle(request);
        }

        let tokenObs: Observable<ITokens>;

        if (!this.tokenService.getAccessToken() && refreshToken) {
            tokenObs = this.tokenService.refreshAccessToken();
        } else {
            tokenObs = of({
                accessToken: this.tokenService.getAccessToken(),
                refreshToken: refreshToken,
            });
        }

        return tokenObs.pipe(
            switchMap((token) => {
                if (!token) {
                    return next.handle(request);
                }
                request = request.clone({
                    withCredentials: true,
                    setHeaders: {
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        Authorization: `Bearer ${token.accessToken}`,
                    },
                });
                return next.handle(request).pipe(
                    catchError((err: HttpErrorResponse) => {
                        if (err?.status === 401) {
                            return this.refreshToken().pipe(
                                switchMap((tokens2) => {
                                    request = request.clone({
                                        setHeaders: {
                                            // eslint-disable-next-line @typescript-eslint/naming-convention
                                            Authorization: `Bearer ${tokens2.accessToken}`,
                                        },
                                    });
                                    return next.handle(request);
                                }),
                                catchError((err2: HttpErrorResponse) => {
                                    if (err2.status === 401) {
                                        this.tokenService.clearTokens();
                                        this.router.navigate(['/account/login']).catch(EMPTY);
                                    }
                                    return throwError(() => err2);
                                }),
                            );
                        }
                        return throwError(() => err);
                    }),
                );
            }),
        );
    }

    private refreshToken(): Observable<ITokens> {
        if (this._refreshTokenInProgress) {
            return new Observable((observer) => {
                this._tokenRefreshed$.subscribe({
                    next: (token) => {
                        observer.next(token);
                        observer.complete();
                    },
                    error: (err) => {
                        observer.error(err);
                        observer.complete();
                    },
                });
            });
        } else {
            this._refreshTokenInProgress = true;

            return this.tokenService.refreshAccessToken().pipe(
                tap((user) => {
                    this._refreshTokenInProgress = false;
                    this._tokenRefreshedSource.next(user);
                }),
                catchError((err) => {
                    this._refreshTokenInProgress = false;
                    this.tokenService.clearTokens();
                    this.router.navigate(['/account/login']).catch(EMPTY);
                    this._tokenRefreshedSource.error(err);
                    return throwError(err);
                }),
            );
        }
    }
}
