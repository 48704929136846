<div class="l-players">
    <div class="d-flex flex-column align-items-center mb-3">
        <button (click)="openInviteModal()" mat-raised-button>
            <mat-icon class="text-themed">person_add</mat-icon> {{ 'INVITE.TITLE' | translate }}
        </button>
    </div>
    <div class="fsTable mat-elevation-z8">
        <div class="fsTable_header">
            <div class="fsTable_cell-head table_cell-avatar"></div>
            <div class="fsTable_cell-head table_cell-name">{{ 'PLAYERS.NAME' | translate }}</div>
            <div class="fsTable_cell-head table_cell-level">{{ 'PLAYERS.LEVEL' | translate }}</div>
            <div class="fsTable_cell-head table_cell-performance">{{ 'PLAYERS.PERFORMANCE' | translate }}</div>
            <div class="fsTable_cell-head table_cell-wins">{{ 'PLAYERS.WINS' | translate }}</div>
        </div>
        <div
            class="line_wrapper"
            *ngFor="let player of players$ | async; let ix = index"
            [class.openDetail]="player.id === activePlayer"
            (click)="showDetail(player)"
        >
            <div
                mat-ripple
                class="fsTable_line table_line clickable"
                [class.first]="ix === 0"
                [class.second]="ix === 1"
                [class.third]="ix === 2"
            >
                <div class="fsTable_cell table_cell-avatar">
                    <span class="avatar">
                        <img [attr.src]="player.id | avatarUrl" alt="" width="69" />
                    </span>
                </div>
                <div class="fsTable_cell table_cell-name">
                    <span class="table_cell_text">{{ player.displayName }}</span>
                </div>
                <div class="fsTable_cell table_cell-level">{{ player.level }}</div>
                <div class="fsTable_cell table_cell-performance">
                    <app-player-performance [performance]="player.performance"></app-player-performance>
                </div>
                <div class="fsTable_cell table_cell-wins">
                    <span class="table_cell_text">{{ player.winCount }}</span>
                </div>
            </div>
            <div class="player_detail" *ngIf="detail$ | async as stats">
                <div class="player_stats" *ngIf="activePlayer === player.id">
                    <div class="player_title text-center mt-4">{{ 'PLAYERS.STATISTICS_TITLE' | translate }}</div>
                    <div class="playersStats_table">
                        <div class="playersStats_header">
                            <div class="playersStats_cell playersStats_cell-head">
                                {{ 'PLAYERS.WINS_TEN_ZERO' | translate: { numberOfGoalsToWin: teamSettings.numberOfGoalsToWin } }}
                            </div>
                            <div class="playersStats_cell playersStats_cell-head">
                                {{ 'PLAYERS.LOSTS_TEN_ZERO' | translate: { numberOfGoalsToWin: teamSettings.numberOfGoalsToWin } }}
                            </div>
                            <div class="playersStats_cell playersStats_cell-head">
                                {{ 'PLAYERS.WINS_TEN_NINE' | translate }}
                            </div>
                            <div class="playersStats_cell playersStats_cell-head">
                                {{ 'PLAYERS.LOSTS_TEN_NINE' | translate }}
                            </div>
                        </div>

                        <div class="playersStats_line">
                            <div class="playersStats_cell">
                                {{ stats.tenToZeroWins }}
                            </div>
                            <div class="playersStats_cell">
                                {{ stats.tenToZeroLosts }}
                            </div>
                            <div class="playersStats_cell">
                                {{ stats.tenToNineWins }}
                            </div>
                            <div class="playersStats_cell">
                                {{ stats.tenToNineLosts }}
                            </div>
                        </div>

                        <div class="playersStats_header mt-3">
                            <div class="playersStats_cell playersStats_cell-head">
                                {{ 'PLAYERS.GOALS' | translate }}
                            </div>
                            <div class="playersStats_cell playersStats_cell-head">
                                {{ 'PLAYERS.GOALS_PER_MATCH' | translate }}
                            </div>
                            <div class="playersStats_cell playersStats_cell-head">
                                {{ 'PLAYERS.GOALS_RECEIVED' | translate }}
                            </div>
                            <div class="playersStats_cell playersStats_cell-head">
                                {{ 'PLAYERS.GOALS_RECEIVED_PER_MATCH' | translate }}
                            </div>
                        </div>
                        <div class="playersStats_line">
                            <div class="playersStats_cell">
                                {{ player.goalCount }}
                            </div>
                            <div class="playersStats_cell">
                                {{ player.goalCount / (player.matchSetCount || 1) | number: '1.0-2' }}
                            </div>
                            <div class="playersStats_cell">
                                {{ player.goalAllowedCount }}
                            </div>
                            <div class="playersStats_cell">
                                {{ player.goalAllowedCount / (player.matchSetCount || 1) | number: '1.0-2' }}
                            </div>
                        </div>

                        <div class="playersStats_header mt-3">
                            <div class="playersStats_cell playersStats_cell-head">
                                {{ 'PLAYERS.WINS_IN_DEFENSE' | translate }}
                            </div>
                            <div class="playersStats_cell playersStats_cell-head">
                                {{ 'PLAYERS.WINS_IN_OFFENSE' | translate }}
                            </div>
                            <div class="playersStats_cell playersStats_cell-head"></div>
                            <div class="playersStats_cell playersStats_cell-head"></div>
                        </div>

                        <div class="playersStats_line">
                            <div matTooltip="{{ stats.defenseWins }} / {{ stats.totalDefense }}" class="playersStats_cell">
                                {{ (stats.defenseWins / (stats.totalDefense || 1)) * 100 | number: '.0-0' }}%
                            </div>
                            <div matTooltip="{{ stats.offenseWins }} / {{ stats.totalOffense }}" class="playersStats_cell">
                                {{ (stats.offenseWins / (stats.totalOffense || 1)) * 100 | number: '.0-0' }}%
                            </div>
                            <div class="playersStats_cell"></div>
                            <div class="playersStats_cell"></div>
                        </div>

                        <div class="player_title text-center mt-4">{{ 'PLAYERS.PLAYER_WIN_RATE' | translate }}</div>
                        <div class="playersStats_table">
                            <div class="playersStats_header">
                                <div
                                    class="playersStats_cell playersStats_cell-head clickable"
                                    (click)="sortWinRate($event, 'displayName')"
                                >
                                    {{ 'PLAYERS.PLAYER' | translate }}
                                    <i class="fas" [ngClass]="getSortClass('displayName')"></i>
                                </div>
                                <div
                                    class="playersStats_cell playersStats_cell-head clickable"
                                    (click)="sortWinRate($event, 'withWinPercent')"
                                >
                                    {{ 'PLAYERS.WITH' | translate }}
                                    <i class="fas" [ngClass]="getSortClass('withWinPercent')"></i>
                                </div>
                                <div
                                    class="playersStats_cell playersStats_cell-head clickable"
                                    (click)="sortWinRate($event, 'againstWinPercent')"
                                >
                                    {{ 'PLAYERS.AGAINST' | translate }}
                                    <i class="fas" [ngClass]="getSortClass('againstWinPercent')"></i>
                                </div>
                            </div>
                            <div class="playersStats_line" *ngFor="let w of stats.winRate">
                                <div class="playersStats_cell">{{ w.displayName }}</div>
                                <div class="playersStats_cell">
                                    {{ w.withWinCount }} / {{ w.withMatchCount }} ({{ w.withWinPercent | number: '.0-0' }}
                                    %)
                                </div>
                                <div class="playersStats_cell">
                                    {{ w.againstWinCount }} / {{ w.againstMatchCount }} ({{ w.againstWinPercent | number: '.0-0' }}
                                    %)
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
