import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { errorActions } from 'store';
import { IErrorInfo } from 'store/error/error.model';

export class ApplicationErrorHandler implements ErrorHandler {
    constructor(private readonly injector: Injector) {}

    handleError(error: any): void {
        // eslint-disable-next-line no-console
        console.error(error);
        try {
            const store = this.injector.get(Store);
            if (error instanceof Error) {
                const errorState: IErrorInfo = {
                    message: error.message,
                    name: error.name,
                    stack: error.stack,
                    url: window.location.href,
                };
                store.dispatch(errorActions.logApplicationException({ error: errorState }));
            }
            if (error instanceof HttpErrorResponse && error.status === 500) {
                store.dispatch(errorActions.apiError({ error: error.error }));
            }
        } catch (innerErr) {
            // eslint-disable-next-line no-console
            console.error(innerErr);
        }
    }
}
