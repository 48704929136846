<div class="d-flex flex-row gap-2">
    <ng-container *ngFor="let p of performance">
        <div *ngIf="p.points === 3" class="bar bar-3 bg-success"></div>
        <div *ngIf="p.points === 0" class="bar bar-3 bg-danger"></div>
        <div *ngIf="p.points === 2" class="d-flex flex-column">
            <div class="bar bg-danger bar-1"></div>
            <div class="bar bg-success bar-2"></div>
        </div>
        <div *ngIf="p.points === 1" class="d-flex flex-column">
            <div class="bar bg-danger bar-2"></div>
            <div class="bar bg-success bar-1"></div>
        </div>
    </ng-container>
</div>
